


































import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ArticleNavigation extends mixins(Navigation) {
  @Prop({}) prevText!: string;
  @Prop({}) prevLink!: string;
  @Prop({}) nextText!: string;
  @Prop({}) nextLink!: string;

  private get prevExists() {
    if (
      this.prevLink !== "" &&
      this.prevLink !== null &&
      this.prevLink !== undefined &&
      this.prevText !== "" &&
      this.prevText !== undefined &&
      this.prevText !== null
    ) {
      return true;
    } else {
      return false;
    }
  }

  private get nextExists() {
    if (
      this.nextLink !== "" &&
      this.nextLink !== null &&
      this.nextLink !== undefined &&
      this.nextText !== "" &&
      this.nextText !== undefined &&
      this.nextText !== null
    ) {
      return true;
    } else {
      return false;
    }
  }
}
